<script setup>
useHead({
  title: "404",
});
const error = useError();
</script>

<template>
  <div id="l-wrapper" class="w-full overflow-hidden">
    <div class="l-container pt-2xl">
      <div class="prose py-lg">
        <h1>Error {{ error.statusCode }}</h1>
        <h2>Oeps, er ging iets mis</h2>
      </div>
    </div>
  </div>
</template>
